
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CreateStaffAndAdminUser from "@/components/admin/CreateStaffAndAdminUser.vue";

export default defineComponent({
  name: "admin-add-new-administrator",
  components: {
    CreateStaffAndAdminUser,
  },
  // data() {
  //   return {
  //     redirect: "admin-administrator-users",
  //   };
  // },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Add New", ["Users", "Administrators"]);
    });
  },
  created() {
    //Set page title
    document.title = "Add New Administrator | " + process.env.VUE_APP_TITLE;
  },
});
